import { setUserInfo } from "../../utils/local-storage";
import { baseApi } from "./baseApi"

import apiEndPOints from "../url";

export const authApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        userLogin: build.mutation({
            query: (loginData) => ({
                url:    apiEndPOints.AUTH_LOGIN,
                method: 'POST',
                data: loginData,
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                try {
                    const result = (await queryFulfilled).data;
                    setUserInfo({ accessToken: result.accessToken });
                } catch (error) {
                }
            },
        }),
        patientSignUp: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.AUTH_PATIENT_SIGNUP,
                method: 'POST',
                data,
            }),
        }),
        doctorSignUp: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.AUTH_DOCTOR_SIGNUP,
                method: 'POST',
                data,
            }),
        }),
        resetPassword: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.AUTH_RESET_PASSWORD,
                method: 'POST',
                data,
            }),
        }),
        resetConfirm: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.AUTH_RESET_CONFIRM,
                method: 'POST',
                data,
            }),
        }),
        changePassword: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.AUTH_CHANGE_PASSWORD,
                method: 'POST',
                data,
            }),
        }),
    })
})

export const { 
    useUserLoginMutation, 
    useDoctorSignUpMutation, 
    usePatientSignUpMutation,
    useResetPasswordMutation, 
    useResetConfirmMutation,
    useChangePasswordMutation
} = authApi