import React from "react";
import "./Footer.css";
import logo from "../../../images/logo1.png";
import { Link } from "react-router-dom";
import { FaAngleDoubleRight } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer position-relative">
      <div className="footer-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-6 col-md-6 col-lg-3 mb-3">
              <div className="footer-widget footer-about">
                <div className="footer-logo">
                  <Link to={"/"}>
                    <img src={logo} alt="logo" style={{ maxWidth: "160px" }} />
                  </Link>
                </div>
                <div className="footer-about-content">
                  <p className="form-text" style={{ maxWidth: 200 }}>
                    Doctor on call is a platform where you can book appointment
                    with doctor, consult online and chat online.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-3">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">For Patients</h2>
                <ul>
                  <li>
                    <Link to={"/doctors"}>
                      <FaAngleDoubleRight className="icon" /> Search for Doctors
                    </Link>
                  </li>
                  <li>
                    <Link to={"/doctors"}>
                      <FaAngleDoubleRight className="icon" /> Booking
                    </Link>
                  </li>
                  <li>
                    <Link to={"/dashboard"}>
                      <FaAngleDoubleRight className="icon" /> Patient Dashboard
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-3">
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">For Doctors</h2>
                <ul>
                  <li>
                    <Link to={"/dashboard/appointments"}>
                      <FaAngleDoubleRight className="icon" /> Appointments
                    </Link>
                  </li>
                  <li>
                    <Link to={"/dashboard"}>
                      <FaAngleDoubleRight className="icon" /> Doctor Dashboard
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-3">
              <div className="footer-widget footer-contact">
                <h2 className="footer-title mt-3 mt-md-0">Contact Us</h2>
                <div className="footer-contact-info">
                  <div className="footer-address d-flex align-items-start mb-3">
                    <span className="me-2">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                    <p className="mb-0">
                      117/93D/4, LIC Road, <br />
                      Near Kali Mandir/Kundan Guest House, Tagore Town, <br />
                      Prayagraj (Uttar Pradesh) 211002
                    </p>
                  </div>
                  <div className="footer-phone d-flex align-items-center mb-3">
                    <span className="me-2">
                      <i className="fas fa-phone-alt"></i>
                    </span>
                    <p className="mb-0">+91 89885 55540</p>
                  </div>
                  <div className="footer-email d-flex align-items-center">
                    <span className="me-2">
                      <i className="fas fa-envelope"></i>
                    </span>
                    <p
                      className="mb-0 text-break"
                      style={{
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      <a
                        href="mailto:drdkpatwa.dermatologist@gmail.com"
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                        }}
                      >
                        drdkpatwa.dermatologist@gmail.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="footer-bottom"
        style={{
          borderTop: "1px solid #346c8bac",
          color: "#fff",
          padding: "10px 0",
        }}
      >
        <div className="container-fluid">
          <div
            className="row"
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Left Section */}
            <div
              className="col-12 col-md-6"
              style={{
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              <p
                className="mb-0"
                style={{
                  fontSize: "14px",
                  margin: "0",
                }}
              >
                Copyright {new Date().getFullYear()} All Rights Reserved
              </p>
            </div>

            {/* Right Section */}
            <div
              className="col-12 col-md-6"
              style={{
                textAlign: "center",
              }}
            >
              <ul
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                  listStyleType: "none",
                  padding: "0",
                  margin: "0",
                  justifyContent: "center",
                }}
              >
                <li>
                  <p
                    className="text-white mb-0"
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Terms and Conditions
                  </p>
                </li>
                <li>
                  <p
                    className="text-white mb-0"
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                  >
                    Policy
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
