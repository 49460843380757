import React, { useEffect, useState } from "react";
import img from "../../images/avatar.jpg";
import "./DashboardSidebar.css";
import { Link} from "react-router-dom";
import useAuthCheck from "../../redux/hooks/useAuthCheck";

import { calculateAge } from "../../utils/calculateAge";
import dayjs from "dayjs";
import { DATE_FORMAT } from "../../constant";
import DashboardSidebarMenu from "./DashboardSidebarMenu";

const DashboardSidebar = () => {
  const { data, role } = useAuthCheck();
  const [age, setAge] = useState("");
 
  const { authChecked } = useAuthCheck();

  const [isLoggedIn, setIsLogged] = useState(false);

  useEffect(() => {
    authChecked && setIsLogged(true);
  }, [authChecked]);

  useEffect(() => {
    setAge(calculateAge(data?.dateOfBirth));
  }, [data?.dateOfBirth]);


  return (
    <div className="profile-sidebar p-3 rounded">
      <div className="p-2 text-center border-bottom">
        {role === "doctor" ? (
          <div className="profile-info text-center">
            <Link to={"/"}>
              <img src={data?.img ? data?.img : img} alt="" />
            </Link>
            <div className="profile-details">
              <h5 className="mb-0">{data?.firstName + " " + data?.lastName}</h5>
              <div>
                <p className="mb-0">{data?.designation}</p>
              </div>
            </div>
          </div>
        ) : (
          <div className="profile-info text-center">
            <Link to={"/"}>
              <img src={data?.img ? data?.img : img} alt="" />
            </Link>
            <div className="profile-details">
              <h5 className="mb-0">{data?.firstName + " " + data?.lastName}</h5>
              <div className="mt-2">
                {data?.dateOfBirth && (
                  <p className=" form-text m-0">
                    {`${
                      dayjs ? dayjs(data?.dateOfBirth).format(DATE_FORMAT) : ""
                    } , ${age ? age : ""}`}{" "}
                  </p>
                )}
                <p className=" form-text m-0">
                  {" "}
                  {`${data?.city ? data?.city : " "} ${
                    data?.city && data?.state ? "," : ""
                  } ${data?.state ? data?.state : ""}`}{" "}
                </p>
                <p className=" form-text m-0">{data?.email}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <nav className="dashboard-menu">
        {" "}
        <DashboardSidebarMenu isdashboard={true} />
      </nav>
    </div>
  );
};
export default DashboardSidebar;
