import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi"
import apiEndPOints from "../url";

export const prescriptionApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getAllPrescriptions: build.query({
            query: () => ({
                url: apiEndPOints.PRESCRIPTION_ALL,
                method: 'GET'
            }),
            providesTags: [tagTypes.prescription]
        }),
        getPrescription: build.query({
            query: (id) => ({
                url: apiEndPOints.PRESCRIPTION_SINGLE(id),
                method: 'GET'
            }),
            providesTags: [tagTypes.prescription]
        }),
        createPrescription: build.mutation({
            query: ({ data }) => ({
                url: apiEndPOints.PRESCRIPTION_CREATE,
                method: 'POST',
                data: data
            }),
            invalidatesTags: [tagTypes.prescription]
        }),
        deletePrescription: build.mutation({
            query: ({id}) => ({
                url: apiEndPOints.PRESCRIPTION_DELETE(id),
                method: 'DELETE',
            }),
            invalidatesTags: [tagTypes.prescription]
        }),
        updatePrescription: build.mutation({
            query: ({ id, data }) => ({
                url:    apiEndPOints.PRESCRIPTION_UPDATE(id),
                method: 'PATCH',
                data: data
            }),
            invalidatesTags: [tagTypes.prescription]
        }),
        updatePrescriptionAndAppointment: build.mutation({
            query: (data) => ({
                url:   apiEndPOints.PRESCRIPTION_UPDATE_APPOINTMENT,
                method: 'PATCH',
                data: data
            }),
            invalidatesTags: [tagTypes.prescription]
        }),
        getDoctorPrescription: build.query({
            query: () => ({
                url:    apiEndPOints.PRESCRIPTION_DOCTOR_PRESCRIPTION,
                method: 'GET'
            }),
            providesTags: [tagTypes.prescription]
        }),
        getPatientPrescription: build.query({
            query: () => ({
                url:    apiEndPOints.PRESCRIPTION_PATIENT_PRESCRIPTION,
                method: 'GET'
            }),
            providesTags: [tagTypes.prescription]
        })
    })
})

export const {
    useCreatePrescriptionMutation,
    useGetAllPrescriptionsQuery,
    useGetPrescriptionQuery,
    useDeletePrescriptionMutation,
    useGetDoctorPrescriptionQuery,
    useGetPatientPrescriptionQuery,
    useUpdatePrescriptionQuery,
    useUpdatePrescriptionAndAppointmentMutation
} = prescriptionApi;