import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"
import apiEndPOints from "../url";

export const staffApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getStaffs: build.query({
            query: (arg) => ({
                url: apiEndPOints.STAFF_ALL,
                method: 'GET',
                params: arg
            }),
            transformResponse: (response) =>{
                return {
                    staff: response.data,
                   
                }
            },
            providesTags: [tagTypes.doctor]
        }),
        getStaff: build.query({
            query: (id) => ({
                url: apiEndPOints.STAFF_SINGLE(id),
                method: 'GET',
            }),
            providesTags: [tagTypes.doctor]
        }),
        getAppointbyDoc: build.query({
            query: (id) => ({
                url: apiEndPOints.STAFF_APPOINTMENT_BY_DOC(id),
                method: 'GET',
            }),
            providesTags: [tagTypes.doctor]
        }),
        getAppointment: build.query({
            query: (id) => ({
                url: apiEndPOints.STAFF_APPOINTMENT(id),
                method: 'GET',
            }),
            providesTags: [tagTypes.doctor]
        }),
        updateStaff: build.mutation({
            query: ({ data, id }) => ({
                url: apiEndPOints.STAFF_UPDATE(id),
                method: 'PATCH',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.doctor]
        })
    })
})

export const { useGetStaffsQuery, useGetStaffQuery, useUpdateStaffMutation,useGetAppointbyDocQuery,useGetAppointmentQuery } = staffApi