import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi"
import apiEndPOints from "../url";

export const commentApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        createComment: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.COMMENT_CREATE,        
                method: 'POST',
                data: data,
                headers: {
                    'Content-Type':'application/json'
                },
            }),
            invalidatesTags: [tagTypes.comment]
        }),
        getAllComment: build.query({
            query: () => ({
                url:    apiEndPOints.COMMENT_ALL,
                method: 'GET',
                
            }),
           
            providesTags: [tagTypes.comment]
        }),
        getSingleComment: build.query({
            query: (id) => ({
                url: apiEndPOints.COMMENT_SINGLE(id),
                method: 'GET'
            }),
            providesTags: [tagTypes.comment]
        }),
        updateComment: build.mutation({
            query: ({ data, id }) => ({
                url: apiEndPOints.COMMENT_UPDATE(id),
                method: 'PATCH',
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }),
            invalidatesTags: [tagTypes.comment]
        }),
        deleteComment: build.mutation({
            query: (id) => ({
                url: apiEndPOints.COMMENT_DELETE(id),
                method: 'DELETE'
            }),
            invalidatesTags: [tagTypes.comment]
        }),
    })
})

export const {
    useCreateCommentMutation,
    useDeleteCommentMutation,
    useGetAllCommentQuery,
    useUpdateCommentMutation,
    useGetSingleCommentQuery
} = commentApi