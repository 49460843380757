import { tagTypes } from "../tag-types";
import apiEndPOints from "../url";
import { baseApi } from "./baseApi"

export const medicineApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        createMedicine: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.MEDICINE_CREATE,
                method: 'POST',
                data: data
            }),
            invalidatesTags: [tagTypes.medicine]
        }),
        updateMedicine: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.MEDICINE_UPDATE,
                method: 'PATCH',
                data: data
            }),
            invalidatesTags: [tagTypes.medicine]
        }),
        deleteMedicine: build.mutation({
            query: (id) => ({
                url: apiEndPOints.MEDICINE_DELETE(id),
                method: 'DELETE',
            }),
            invalidatesTags: [tagTypes.medicine]
        }),

    })
})

export const {
    useCreateMedicineMutation,
    useUpdateMedicineMutation,
    useDeleteMedicineMutation
} = medicineApi;