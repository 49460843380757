import { getUserInfo } from "../../service/auth.service";
import { Navigate } from "react-router-dom";

const PrivateLoginProctor = ({ children }) => {
  const localAuth = getUserInfo();
  if (
    localAuth?.role === "doctor" ||
    localAuth?.role === "patient" ||
    localAuth?.role === "staff"
  ) {
    return <Navigate to="/" />;
  }

  return children;
};

export default PrivateLoginProctor;
