import { Outlet, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../service/auth.service";
import { useEffect } from "react";

const PrivateOutlet = ({ role }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const localAuth = getUserInfo();
    console.log(localAuth);

    if (!localAuth) {
      navigate("/login", { replace: true });
    }

    if (localAuth?.role !== role && role !== "all") {
      navigate("/login", { replace: true });
    }
  }, [navigate]);

  return <Outlet />;
};

export default PrivateOutlet;
