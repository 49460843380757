import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"
import apiEndPOints from "../url";

export const clinicApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getDoctorClinics: build.query({
            query: (doctorId) => ({
                url: apiEndPOints.CLINIC_ALL(doctorId),
                method: 'GET',
            }),
            providesTags: [tagTypes.clinic]
        }),
        editDoctorClinic: build.mutation({
            query: (data) => ({
                url:    apiEndPOints.CLINIC_EDIT,
                method: 'PATCH',
                data: data
            }),
            invalidatesTags: [tagTypes.clinic]
        }),
        deleteDoctorClinic: build.mutation({
            query: (id) => ({
                url: apiEndPOints.CLINIC_DELETE(id),
                method: 'DELETE'
            }),
            invalidatesTags: [tagTypes.clinic]
        }),
        createDoctorClinic: build.mutation({
            query: (data) => ({
                url:   apiEndPOints.CLINIC_CREATE,
                method: 'POST',
                data: data
            }),
            invalidatesTags: [tagTypes.clinic]
        }),
        getSpecificClinic: build.query({
            query: (id) => ({
                url: apiEndPOints.CLINIC_SINGLE(id),
                method: 'GET'
            }),
            providesTags: [tagTypes.clinic]
        })
    })
});

export const {
    useCreateDoctorClinicMutation,
    useDeleteDoctorClinicMutation,
    useEditDoctorClinicMutation,
    useGetDoctorClinicsQuery,
    useGetSpecificClinicQuery
} = clinicApi