import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi";
import apiEndPOints from "../url";

export const blogApi = baseApi.injectEndpoints({

  endpoints: (build) => ({
    createBlog: build.mutation({
      query: (data) => ({
        url: apiEndPOints.BLOG_CREATE,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [tagTypes.blogs],
    }),
    getAllBlogs: build.query({
      query: (arg) => ({
        url: apiEndPOints.BLOGS_ALL,
        method: "GET",
        params: arg,
      }),
      transformResponse: (response) => {
        return {
          blogs: response.data,
          meta: response.meta,
        };
      },
      providesTags: [tagTypes.blogs],
    }),
    getSingleBlog: build.query({
      query: (id) => ({
        url: apiEndPOints.BLOG_SINGLE(id),
        method: "GET",
      }),
      providesTags: [tagTypes.blogs],
    }),
    updateBlog: build.mutation({
      query: ({ data, id }) => ({
        url: apiEndPOints.BLOG_UPDATE(id),
        method: "PATCH",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
      invalidatesTags: [tagTypes.blogs],
    }),
    deleteBlog: build.mutation({
      query: (id) => ({
        url: apiEndPOints.BLOG_DELETE(id),
        method: "DELETE",
      }),
      invalidatesTags: [tagTypes.blogs],
    }),
    getBlogsByDoctor:build.query({
      query:(id)=>({
        url: apiEndPOints.BLOG_GETBY_DOCTOR(id),
        method: "GET"
      })
    })
  }),
});

export const {
  useCreateBlogMutation,
  useDeleteBlogMutation,
  useGetAllBlogsQuery,
  useUpdateBlogMutation,
  useGetSingleBlogQuery,
  useGetBlogsByDoctorQuery
} = blogApi;

 