
const mainHost = ""; 
const BaseComponent_Url = {
  APPOINTMENT_URL: "/appointment",
  AUTH_URL: "/auth",
  BLOG_URL: "/blogs",
  CLINIC_URL: "/clinic",
  COMMENT_URL: "/comment",
  DOC_URL: "/doctor",
  FAVOURITE_URL: "/favourite",
  MEDICINE_URL : '/medicine',
  OVERVIEW_URL: '/overview',
  PAT_URL : '/patient',
  PRESCRIPTION_URL : '/prescription',
  REVIEW_URL : '/review',
  STAFF_URL: '/staff',
  TIMELOT_URL : '/timeslot',
  CONTACT_URL:''
};



const apiEndPOints = {
  //appointmentApi
  APPOINTMENT_CREATE: `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/create`,
  APPOINTMENT_CREATE_UNAUTHENTICATE: `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/create-un-authenticate`,
  APPOINTMENT_TRACKING: `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/tracking`,
  APPOINTMENT_UPDATE: (id) =>
    `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/${id}`,
  APPOINTMENT_PATIENT_APPOINTMENTS: `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/patient/appointments`,
  APPOINTMENT_SINGLE: (id) =>
    `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/${id}`,
  APPOINTMENT_PATIENT_PAYMENT_INFO: (id) =>
    `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/patient-payment-info/${id}`,
  APPOINTMENT_DOCTOR_APPOINTMENTS: `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/doctor/appointments`,
  APPOINTMENT_DOCTOR_PATIENTS: `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/doctor/patients`,
  APPOINTMENT_PATIENT_INVOICES: `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/patient/invoices`,
  APPOINTMENT_DOCTOR_INVOICES: `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/doctor/invoices`,
  APPOINTMENT_DOCTOR_APPOINTMENTS_TIMES: (doctorId) =>
    `${mainHost}${BaseComponent_Url.APPOINTMENT_URL}/doctor/appointments/times/${doctorId}`,

  //authApi
  AUTH_LOGIN: `${mainHost}${BaseComponent_Url.AUTH_URL}/login`,
  AUTH_PATIENT_SIGNUP: `${mainHost}/patient`,
  AUTH_DOCTOR_SIGNUP: `${mainHost}/doctor`,
  AUTH_RESET_PASSWORD: `${mainHost}${BaseComponent_Url.AUTH_URL}/reset-password`,
  AUTH_RESET_CONFIRM: `${mainHost}${BaseComponent_Url.AUTH_URL}/reset-password/confirm`,
  AUTH_CHANGE_PASSWORD: `${mainHost}${BaseComponent_Url.AUTH_URL}/change-password`,

  //blogApi
  BLOG_CREATE: `${mainHost}${BaseComponent_Url.BLOG_URL}`,
  BLOGS_ALL: `${mainHost}${BaseComponent_Url.BLOG_URL}/`,

  BLOG_SINGLE: (id) => `${mainHost}${BaseComponent_Url.BLOG_URL}/${id}`,
  BLOG_UPDATE: (id) => `${mainHost}${BaseComponent_Url.BLOG_URL}/${id}`,
  BLOG_DELETE: (id) => `${mainHost}${BaseComponent_Url.BLOG_URL}/${id}`,
  BLOG_GETBY_DOCTOR:(id)=>`${mainHost}${BaseComponent_Url.BLOG_URL}/doctor/${id}`,
  //clinicApi
  CLINIC_ALL: (doctorId) =>
    `${mainHost}${BaseComponent_Url.CLINIC_URL}/all/${doctorId}`,
  CLINIC_EDIT: `${mainHost}${BaseComponent_Url.CLINIC_URL}`,
  CLINIC_DELETE: (id) => `${mainHost}${BaseComponent_Url.CLINIC_URL}/${id}`,
  CLINIC_CREATE: `${mainHost}${BaseComponent_Url.CLINIC_URL}/create`,
  CLINIC_SINGLE: (id) => `${mainHost}${BaseComponent_Url.CLINIC_URL}/${id}`,

  //contactApi
  CONTACT: `${mainHost}${BaseComponent_Url.CONTACT_URL}/contact`,

  //commentApi
  COMMENT_CREATE: `${mainHost}${BaseComponent_Url.COMMENT_URL}`,
  COMMENT_ALL: `${mainHost}${BaseComponent_Url.COMMENT_URL}/`,
  COMMENT_SINGLE: (id) => `${mainHost}${BaseComponent_Url.COMMENT_URL}/${id}`,
  COMMENT_UPDATE: (id) => `${mainHost}${BaseComponent_Url.COMMENT_URL}/${id}`,
  COMMENT_DELETE: (id) => `${mainHost}${BaseComponent_Url.COMMENT_URL}/${id}`,

  //doctorApi
  DOCTOR_ALL: `${mainHost}${BaseComponent_Url.DOC_URL}`,
  DOCTOR_SINGLE: (id) => `${mainHost}${BaseComponent_Url.DOC_URL}/${id}`,
  DOCTOR_UPDATE: (id) => `${mainHost}${BaseComponent_Url.DOC_URL}/${id}`,

  //favouriteApi
  FAVOURITE_ADD: `${mainHost}${BaseComponent_Url.FAVOURITE_URL}/add`,
  FAVOURITE_REMOVE: `${mainHost}${BaseComponent_Url.FAVOURITE_URL}/remove`,
  FAVOURITE_GET: `${mainHost}${BaseComponent_Url.FAVOURITE_URL}/`,

  //medicineApi
  MEDICINE_CREATE: `${mainHost}${BaseComponent_Url.MEDICINE_URL}/`,
  MEDICINE_UPDATE: `${mainHost}${BaseComponent_Url.MEDICINE_URL}/`,
  MEDICINE_DELETE: (id) => `${mainHost}${BaseComponent_Url.MEDICINE_URL}/${id}`,

  //overviewApi
  OVERVIEW_GET: (args) =>
    `${mainHost}${BaseComponent_Url.OVERVIEW_URL}/${args}}`,

  //patientApi
  PATIENT_SINGLE: (id) => `${mainHost}${BaseComponent_Url.PAT_URL}/${id}`,
  PATIENT_UPDATE: (id) => `${mainHost}${BaseComponent_Url.PAT_URL}/${id}`,

  //prescriptionApi
  PRESCRIPTION_ALL: `${mainHost}${BaseComponent_Url.PRESCRIPTION_URL}`,
  PRESCRIPTION_SINGLE: (id) =>
    `${mainHost}${BaseComponent_Url.PRESCRIPTION_URL}/${id}`,
  PRESCRIPTION_CREATE: `${mainHost}${BaseComponent_Url.PRESCRIPTION_URL}/create`,
  PRESCRIPTION_UPDATE: (id) =>
    `${mainHost}${BaseComponent_Url.PRESCRIPTION_URL}/${id}`,
  PRESCRIPTION_DELETE: (id) =>
    `${mainHost}${BaseComponent_Url.PRESCRIPTION_URL}/${id}`,
  PRESCRIPTION_UPDATE_APPOINTMENT: `${mainHost}${BaseComponent_Url.PRESCRIPTION_URL}/update-prescription-appointment`,
  PRESCRIPTION_DOCTOR_PRESCRIPTION: `${mainHost}${BaseComponent_Url.PRESCRIPTION_URL}/doctor/prescription`,
  PRESCRIPTION_PATIENT_PRESCRIPTION: `${mainHost}${BaseComponent_Url.PRESCRIPTION_URL}/patient/prescription`,

  //reviewApi
  REVIEW_ALL: `${mainHost}${BaseComponent_Url.REVIEW_URL}`,
  REVIEW_SINGLE: (id) => `${mainHost}${BaseComponent_Url.REVIEW_URL}/${id}`,
  REVIEW_DOCTOR_REVIEW: (id) =>
    `${mainHost}${BaseComponent_Url.REVIEW_URL}/doctor-review/${id}`,
  REVIEW_REPLY: (id) =>
    `${mainHost}${BaseComponent_Url.REVIEW_URL}/${id}/reply`,
  REVIEW_CREATE: `${mainHost}${BaseComponent_Url.REVIEW_URL}/`,
  REVIEW_DELETE: (id) => `${mainHost}${BaseComponent_Url.REVIEW_URL}/${id}`,
  REVIEW_UPDATE: (id) => `${mainHost}${BaseComponent_Url.REVIEW_URL}/${id}`,

  //staffApi
  STAFF_ALL: `${mainHost}${BaseComponent_Url.STAFF_URL}`,
  STAFF_SINGLE: (id) => `${mainHost}${BaseComponent_Url.STAFF_URL}/${id}`,
  STAFF_APPOINTMENT_BY_DOC: (id) =>
    `${mainHost}${BaseComponent_Url.STAFF_URL}/appoinmentbydoc/${id}`,
  STAFF_APPOINTMENT: (id) =>
    `${mainHost}${BaseComponent_Url.STAFF_URL}/appoinment/${id}`,
  STAFF_UPDATE: (id) => `${mainHost}${BaseComponent_Url.STAFF_URL}/${id}`,

  //timeslotApi
  TIMELOT_CREATE: `${mainHost}${BaseComponent_Url.TIMELOT_URL}/create`,
  TIMELOT_ALL: `${mainHost}${BaseComponent_Url.TIMELOT_URL}/`,
  TIMELOT_SINGLE: (id) => `${mainHost}${BaseComponent_Url.TIMELOT_URL}/${id}`,
  TIMELOT_APPOINTMENT_TIME: (id) =>
    `${mainHost}${BaseComponent_Url.TIMELOT_URL}/appointment-time/${id}`,
  TIMELOT_DOCTOR_SLOT: `${mainHost}${BaseComponent_Url.TIMELOT_URL}/my-slot`,
  TIMELOT_DELETE: `${mainHost}${BaseComponent_Url.TIMELOT_URL}/`,
  TIMELOT_UPDATE: `${mainHost}${BaseComponent_Url.TIMELOT_URL}`,
};

export default apiEndPOints;