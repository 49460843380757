import React, { useCallback, useEffect, useState } from "react";
import Footer from "../../Shared/Footer/Footer";
import img from "../../../images/doc/doctor 3.jpg";
import "./index.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Empty, Button, message, Steps } from "antd";
import { useGetDoctorQuery } from "../../../redux/api/doctorApi";
import { FaArchway } from "react-icons/fa";
import { useLazyGetAppointmentTimeQuery } from "../../../redux/api/timeSlotApi";
import moment from "moment";
import SelectDateAndTime from "../SelectDateAndTime";
import PersonalInformation from "../PersonalInformation";
import CheckoutPage from "../BookingCheckout/CheckoutPage";
import { useCreateAppointmentMutation } from "../../../redux/api/appointmentApi";
import { useDispatch } from "react-redux";
import { addInvoice } from "../../../redux/feature/invoiceSlice";
import Header from "../../Shared/Header/Header";
import useAuthCheck from "../../../redux/hooks/useAuthCheck";
import { userRole } from "../../../constant/role";

import { validate } from "../../../utils/personalInformation";

const DoctorBooking = () => {
  const dispatch = useDispatch();
  let initialValue = {
    paymentMethod: "Offline",
    paymentType: "Cash",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    reasonForVisit: "",
    description: "",
    address: "",
    nameOnCard: "",
    cardNumber: "",
    expiredMonth: "",
    cardExpiredYear: "",
    cvv: "",
    sex: "", // Added sex
    age: "", // Added age

    weight: "", // Added weight
  };
  const { data: loggedInUser, role } = useAuthCheck();
  const [current, setCurrent] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectDay, setSelecDay] = useState("");
  const [clinicId, setClinicId] = useState("");
  const [selectTime, setSelectTime] = useState("");
  const [isCheck, setIsChecked] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [
    createAppointment,
    {
      data: appointmentData,
      isSuccess: createIsSuccess,
      isError: createIsError,
      error: createError,
      isLoading: createIsLoading,
    },
  ] = useCreateAppointmentMutation();
  const { doctorId } = useParams();
  const navigation = useNavigate();
  const { data, isLoading, isError, error } = useGetDoctorQuery(doctorId);
  const [
    trigger,
    {
      data: time,
      refetch,
      isLoading: dIsLoading,
      isError: dIsError,
      error: dError,
      isSuccess: appointmentTimesSuccess,
    },
  ] = useLazyGetAppointmentTimeQuery();

  const [selectValue, setSelectValue] = useState(initialValue);
  const [IsdDisable, setIsDisable] = useState(true);
  const [IsConfirmDisable, setIsConfirmDisable] = useState(false);

  const handleChange = (e) => {
    setSelectValue({ ...selectValue, [e.target.name]: e.target.value });
    setValidation(false);

    setErrors({ ...errors, [e.target.name]: "" });
  };

  useEffect(() => {
    const {
      firstName,
      lastName,
      email,
      phone,
      nameOnCard,
      cardNumber,
      expiredMonth,
      cardExpiredYear,
      cvv,
      reasonForVisit,
      sex,
      age,
      weight,
    } = selectValue;
    const isInputEmpty =
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !reasonForVisit ||
      !sex ||
      !age ||
      !weight ||
      !validation;

    const isConfirmInputEmpty =
      !nameOnCard ||
      !cardNumber ||
      !expiredMonth ||
      !cardExpiredYear ||
      !cvv ||
      !isCheck;
    setIsDisable(isInputEmpty);
    setIsConfirmDisable(isConfirmInputEmpty);
  }, [selectValue, isCheck]);

  const handleDateChange = async (_date, dateString) => {
    setSelectedDate(dateString);
    setSelecDay(() => moment(dateString).format("dddd").toLowerCase());
    trigger({
      day: dateString,
      id: doctorId,
      clinicId: clinicId,
    });
  };

  const handleClinicChange = (clinicId) => {
    setClinicId(clinicId);
    trigger({ day: selectedDate, id: doctorId, clinicId: clinicId });
  };

  const disabledDateTime = (current) =>
    current &&
    (current < moment().add(1, "day").startOf("day") ||
      current > moment().add(8, "days").startOf("day"));
  const handleSelectTime = (date) => {
    setSelectTime(date);
  };

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };

  const {
    firstName,
    lastName,
    email,
    phone,
    reasonForVisit,
    description,
    address,
    sex,
    age,
    weight,
  } = selectValue;
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (
      validate(
        firstName,
        lastName,
        email,
        phone,
        reasonForVisit,
        description,
        address,
        sex,
        age,
        weight,
        setErrors
      )
    ) {
      next();
      console.log("Form values:", selectValue);
    } else {
      console.log("Validation errors:", errors);
    }
  };

  const [validation, setValidation] = useState(false);

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [current]);

  let dContent = null;
  if (dIsLoading) dContent = <div>Loading ...</div>;
  if (!dIsLoading && dIsError) dContent = <div>Something went Wrong!</div>;
  if (!dIsLoading && !dIsError && time?.length === 0)
    dContent = <Empty children="Doctor Is not Available" />;
  if (!dIsLoading && !dIsError && time?.length > 0)
    dContent = (
      <>
        {time &&
          time.map((item, id) => (
            <div className="col-md-4" key={id + 155}>
              <Button
                type={item?.slot?.time === selectTime ? "primary" : "default"}
                shape="round"
                size="large"
                className="mb-3"
                onClick={() => handleSelectTime(item?.slot?.time)}
              >
                {" "}
                {item?.slot?.time}{" "}
              </Button>
            </div>
          ))}
      </>
    );

  //What to render
  let content = null;
  if (!isLoading && isError) content = <div>Something Went Wrong!</div>;
  if (!isLoading && !isError && data?.id === undefined) content = <Empty />;
  if (!isLoading && !isError && data?.id)
    content = (
      <>
        <div className="booking-doc-img my-3 mb-3 rounded">
          <Link to={`/doctors/profile/${data?.id}`}>
            <img src={data?.img} alt="" />
          </Link>
          <div className="text-start">
            <Link
              to={`/doctors/profile/${data?.id}`}
              style={{ textDecoration: "none" }}
            >
              Dr. {data?.firstName + " " + data?.lastName}
            </Link>
            <p className="form-text mb-0">
              {data?.specialization && data?.experienceHospitalName
                ? <FaArchway /> +
                  data?.specialization +
                  " ," +
                  data?.experienceHospitalName
                : ""}
            </p>
          </div>
        </div>
      </>
    );

  const steps = [
    {
      title: "Select Appointment Date & Time",
      content: (
        <SelectDateAndTime
          content={content}
          handleDateChange={handleDateChange}
          handleClinicChange={handleClinicChange}
          disabledDateTime={disabledDateTime}
          selectedDate={selectedDate}
          dContent={dContent}
          selectTime={selectTime}
          doctorId={doctorId}
        />
      ),
    },
    {
      title: "Patient Information",
      content: (
        <PersonalInformation
          handleChange={handleChange}
          selectValue={selectValue}
          setPatientId={setPatientId}
          errors={errors}
        />
      ),
    },
    {
      title: "Payment",
      content: (
        <CheckoutPage
          handleChange={handleChange}
          selectValue={selectValue}
          isCheck={isCheck}
          setIsChecked={setIsChecked}
          data={data}
          selectedDate={selectedDate}
          selectTime={selectTime}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const handleConfirmSchedule = () => {
    const obj = {};
    obj.patientInfo = {
      firstName: selectValue.firstName,
      lastName: selectValue.lastName,
      email: selectValue.email,
      phone: selectValue.phone,
      scheduleDate: selectedDate,
      scheduleTime: selectTime,
      doctorId: doctorId,
      sex: selectValue.sex, // Added sex
      age: selectValue.age, // Added age
      weight: selectValue.weight, // Added weight
      reasonForVisit: selectValue.reasonForVisit, // Added reasonForVisit
      description: selectValue.description, // Added description
      address: selectValue.address, // Added address
      patientId:
        role && role === userRole.patient ? loggedInUser?.id : undefined,
      clinicId: clinicId,
    };
    obj.payment = {
      paymentType: selectValue.paymentType,
      paymentMethod: selectValue.paymentMethod,
      cardNumber: selectValue.cardNumber,
      cardExpiredYear: selectValue.cardExpiredYear,
      cvv: selectValue.cvv,
      expiredMonth: selectValue.expiredMonth,
      nameOnCard: selectValue.nameOnCard,
    };
    createAppointment(obj);
  };

  useEffect(() => {
    if (createIsSuccess) {
      message.success("Succcessfully Appointment Scheduled");
      setSelectValue(initialValue);
      dispatch(addInvoice({ ...appointmentData }));
      navigation(`/booking/success/${appointmentData.id}`);
    }
    if (createIsError) {
      message.error(error?.data?.message);
    }
  }, [createIsSuccess, createError]);

  return (
    <>
      <Header />
      <div
        className="container"
        style={{ marginBottom: "12rem", marginTop: "8rem" }}
      >
        <Steps current={current} items={items} />
        <div className="mb-5 mt-3 mx-3">{steps[current].content}</div>
        <div className="text-end mx-3">
          {current === 0 && (
            <Button
              type="primary"
              disabled={
                current === 0
                  ? selectTime
                    ? false
                    : true
                  : (IsdDisable || !selectTime) && !validation
              }
              onClick={() => next()}
            >
              Next
            </Button>
          )}

          {current === 1 && (
            <Button type="primary" onClick={() => handleSubmit()}>
              Next
            </Button>
          )}

          {current === steps.length - 1 && (
            <Button
              type="primary"
              loading={createIsLoading}
              onClick={handleConfirmSchedule}
              disabled={!isCheck}
            >
              Confirm
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DoctorBooking;
