import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Workbox } from "workbox-window";

export function registerServiceWorker() {
  if ("serviceWorker" in navigator) {
    const wb = new Workbox("/service-worker.js");

    wb.addEventListener("waiting", () => {
      showUpdatePrompt(() => {
        wb.addEventListener("controlling", () => {
          window.location.reload();
        });

        wb.messageSW({ type: "SKIP_WAITING" });
      });
    });

    wb.register();
  }
}

// React Component for the Update Prompt
function UpdatePrompt({ onAccept, onDecline }) {
  return (
    <div style={styles.modal}>
      <p>A new version is available. Would you like to update?</p>
      <button style={styles.button} onClick={onAccept}>
        Update
      </button>
      <button style={styles.button} onClick={onDecline}>
        Cancel
      </button>
    </div>
  );
}

// Function to render the UpdatePrompt React Component
function showUpdatePrompt(onAccept) {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
  };

  ReactDOM.render(
    <UpdatePrompt
      onAccept={() => {
        handleClose();
        onAccept();
      }}
      onDecline={handleClose}
    />,
    container
  );
}

// Inline styles for simplicity
const styles = {
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
    textAlign: "center",
    borderRadius: "8px",
  },
  button: {
    margin: "10px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "16px",
    borderRadius: "4px",
    border: "none",
    backgroundColor: "#007BFF",
    color: "#fff",
  },
};
