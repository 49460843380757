import { tagTypes } from "../tag-types"
import { baseApi } from "./baseApi"
import apiEndPOints from "../url";
export const overviewApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        getOverview: build.query({
            query: (args) => ({
                url:apiEndPOints.OVERVIEW_GET(args),
                method: 'GET',
                
            }),
            providesTags: [tagTypes.overview]
        })
    })
})
export const {
    useGetOverviewQuery
}=overviewApi