import { tagTypes } from "../tag-types";
import { baseApi } from "./baseApi"
import apiEndPOints from "../url"

export const appointmentApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        createAppointment: build.mutation({
            query: (data) => ({
                url: apiEndPOints.APPOINTMENT_CREATE,   
                method: 'POST',
                data: data
            }),
            invalidatesTags: [tagTypes.appointments]
        }),
        createAppointmentByUnauthenticateUser: build.mutation({
            query: (data) => ({
                url: apiEndPOints.APPOINTMENT_CREATE_UNAUTHENTICATE,   
                method: 'POST',
                data: data
            }),
            invalidatesTags: [tagTypes.appointments]
        }),
        trackAppointment: build.mutation({
            query: (data) => ({
                url: apiEndPOints.APPOINTMENT_TRACKING, 
                method: 'POST',
                data: data
            })
        }),
        updateAppointment: build.mutation({
            query: ({ id, data, reason }) => ({
                url: apiEndPOints.APPOINTMENT_UPDATE(id),
                method: 'PATCH',
                data: { data, reason }
            }),
            invalidatesTags: [tagTypes.appointments]
        }),
        getPatientAppointments: build.query({
            query: () => ({
                url: apiEndPOints.APPOINTMENT_PATIENT_APPOINTMENTS,
                method: 'GET'
            }),
            providesTags: [tagTypes.appointments]
        }),
        getSingleAppointment: build.query({
            query: (id) => ({
                url: apiEndPOints.APPOINTMENT_SINGLE(id),
                method: 'GET'
            }),
            providesTags: [tagTypes.appointments]
        }),
        getAppointmentedPaymentInfo: build.query({
            query: (id) => ({
                url: apiEndPOints.APPOINTMENT_PATIENT_PAYMENT_INFO(id),
                method: 'GET'
            }),
            providesTags: [tagTypes.appointments]
        }),
        getDoctorAppointments: build.query({
            query: (arg) => ({
                url:    apiEndPOints.APPOINTMENT_DOCTOR_APPOINTMENTS,
                method: 'GET',
                params: arg
            }),
            providesTags: [tagTypes.appointments]
        }),
        getDoctorPatients: build.query({
            query: () => ({
                url:    apiEndPOints.APPOINTMENT_DOCTOR_PATIENTS,
                method: 'GET'
            }),
            providesTags: [tagTypes.appointments]
        }),
        getPatientInvoices: build.query({
            query: () => ({
                url:    apiEndPOints.APPOINTMENT_PATIENT_INVOICES,
                method: 'GET'
            }),
            providesTags: [tagTypes.appointments]
        }),
        getDoctorInvoices: build.query({
            query: () => ({
                url:    apiEndPOints.APPOINTMENT_DOCTOR_INVOICES,
                method: 'GET'
            }),
            providesTags: [tagTypes.appointments]
        }),
        getDoctorPreviousAppointmentsTimes: build.query({
            query: ({ doctorId, clinicId, day }) => ({
                url:    apiEndPOints.APPOINTMENT_DOCTOR_APPOINTMENTS_TIMES(doctorId),
                params: { clinicId: clinicId, day: day }
            }),
            providesTags: [tagTypes.appointmentsTimes]
        })
    })
})

export const {
    useGetDoctorAppointmentsQuery,
    useGetPatientAppointmentsQuery,
    useGetDoctorPatientsQuery,
    useCreateAppointmentMutation,
    useGetSingleAppointmentQuery,
    useGetAppointmentedPaymentInfoQuery,
    useGetPatientInvoicesQuery,
    useGetDoctorInvoicesQuery,
    useUpdateAppointmentMutation,
    useCreateAppointmentByUnauthenticateUserMutation,
    useTrackAppointmentMutation,
    useLazyGetDoctorPreviousAppointmentsTimesQuery
} = appointmentApi;